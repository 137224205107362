(function ($) {
  $.fn.addTooltip = window.showTooltip = function (content, evt, element) {
    if (!content) return this;
    var tip = $('#tooltip');

    // without element
    if (this === window) {
      tip.html(content).css(evtPosition(evt, tip)).removeClass('d-none');
      if (window.tooltipTimer) clearTimeout(window.tooltipTimer);
      window.tooltipTimer = setTimeout(function () {
        tip.addClass('d-none');
      }, 1000);
    }

    // with element
    else {
      $(this)
        .off('.tooltip')
        .on('mouseover.tooltip', function (evt) {
          if (element) content = $(content).clone();
          tip.html(content).css(evtPosition(evt, tip)).removeClass('d-none');
        })
        .on('mouseout.tooltip mouseleave.tooltip click.tooltip', function () {
          tip.addClass('d-none');
          if (window.tooltipTimer) clearTimeout(window.tooltipTimer);
          window.tooltipTimer = setTimeout(function () {
            tip.addClass('d-none');
          }, 1000);
        })
        .on('mousemove.tooltip', function (evt) {
          tip.css(evtPosition(evt, tip)).removeClass('d-none');
          if (window.tooltipTimer) clearTimeout(window.tooltipTimer);
        });
      return this;
    }
  };

  $.fn.removeClassStartingWith = function (filter) {
    $(this).removeClass(function (index, className) {
      return (
        className.match(new RegExp('\\S*' + filter + '\\S*', 'g')) || []
      ).join(' ');
    });
    return this;
  };

  $.fn.onVisible = function (callback) {
    new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          callback();
          observer.disconnect();
        }
      });
    }).observe(this[0]);
  };

  $.fn.showImagePreview = function () {
    var src = $(this).attr('src');
    if (!src) return;

    $(this).addTooltip(this, null, true);
  };

  $.fn.showPdfPreview = function () {
    var canvas = this[0];
    var src = $(this).attr('src');
    if (!canvas || !src) return;

    new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          pdfjsLib.getDocument(src).promise.then(
            function (pdf) {
              pdf.getPage(1).then(function (page) {
                var viewport = page.getViewport({scale: 1.5});
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                page.render({
                  canvasContext: context,
                  viewport: viewport,
                });
              });
            },
            function () {
              $(canvas).remove();
            }
          );
          observer.disconnect();
        }
      });
    }).observe(canvas);

    $(this).addPdfTooltip(src);
  };

  $.fn.addPdfTooltip = function (src) {
    var showTimer;
    var hideTimer;

    $(this)
      .on('mouseover', function (evt) {
        showTimer = setTimeout(function () {
          clearTimeout(hideTimer);
          $('.pdf-preview').remove();
          pdfPreview = $('<canvas class="pdf-preview"></canvas>')
            .appendTo(document.body)
            .on(click, function () {
              $('.pdf-preview').remove();
            });
          var canvas = pdfPreview[0];
          pdfjsLib.getDocument(src).promise.then(
            function (pdf) {
              pdf.getPage(1).then(function (page) {
                var viewport = page.getViewport({scale: 1.5});
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                page.render({
                  canvasContext: context,
                  viewport: viewport,
                });
                if (pdfPreview) pdfPreview.css(evtPosition(evt, pdfPreview));
              });
            },
            function () {}
          );
        }, 500);
      })
      .on('mousemove', function (evt) {
        // console.log('move');
        if (pdfPreview) pdfPreview.css(evtPosition(evt, pdfPreview));
        evt.stopPropagation();
      })
      .on('mouseout', function () {
        // console.log('out');
        clearTimeout(showTimer);
        hideTimer = setTimeout(function () {
          if (pdfPreview) pdfPreview.remove();
          pdfPreview = null;
        }, 500);
      });
  };

  // insensitive-contains
  jQuery.expr[':'].icontains = function (a, i, m) {
    return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
  };
})(jQuery);
